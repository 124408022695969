<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-add-wrapper">
      <validation-observer ref="CreateInventory">
        <!-- form -->
        <b-form>
          <b-row class="mt-1">
            <!-- Name -->
            <b-col md="12">
              <b-form-group class="mb-2">
                <validation-provider #default="{ errors }" name="Tên Kho" rules="required">
                  <span class="d-block" style="margin-bottom:8px">
                    Tên Kho
                    <span class="text-danger">*</span>
                  </span>
                  <b-form-input v-model="form.name" />
                  <small class="text-danger">{{ errors[0]?'Tên Kho không được để trống':'' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Description -->
            <b-col md="6">
              <b-form-group label="Mô tả" class="mb-2">
                <validation-provider #default="{ errors }" name="Mô tả">
                  <b-form-input v-model="form.description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Location -->
            <b-col md="6">
              <b-form-group label="Địa chỉ" class="mb-2">
                <validation-provider #default="{ errors }" name="Địa chỉ">
                  <b-form-input v-model="form.location" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Organization -->
            <b-col md="6">
              <b-form-group class="mb-2">
                <validation-provider #default="{ errors }" name="Tổ chức" rules="required">
                  <span class="d-block" style="margin-bottom:8px">
                    Tổ Chức
                    <span class="text-danger">*</span>
                  </span>
                  <v-select
                    label="name"
                    v-model="form.org_id"
                    :clearable="false"
                    :options="organizations"
                    :reduce="option=>option.id"
                    @input="handleSelectOrg"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <feather-icon icon="ChevronDownIcon" size="20" />
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0]?'Vui lòng chọn Tổ chức':'' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Work space -->
            <b-col md="6">
              <b-form-group  class="mb-2">
                <validation-provider #default="{ errors }" name="Khoa/Phòng Ban" rules="required">
                  <span class="d-block" style="margin-bottom:8px">
                    Khoa/Phòng Ban
                    <span class="text-danger">*</span>
                  </span>
                  <v-select
                    label="name"
                    :reduce="option=>option.id"
                    v-model="form.ws_id"
                    :options="workspaces"
                    @input="()=>{
                    form.manager_id=null
                    getMembers('',form.org_id,form.ws_id)}"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <feather-icon icon="ChevronDownIcon" size="20" />
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0]?'Vui lòng chọn Khoa/Phòng Ban':'' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group class="mb-2">
                <validation-provider #default="{ errors }" name="Quản lý" rules="required">
                  <span class="d-block" style="margin-bottom:8px">
                    Quản lý
                    <span class="text-danger">*</span>
                  </span>
                  <v-select
                    v-model="form.manager_id"
                    label="name"
                    :filterable="false"
                    :reduce="option=>option.user_id"
                    :options="members"
                    @search="handleDebounce"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <feather-icon icon="ChevronDownIcon" size="20" />
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0]?'Vui lòng chọn Người Quản Lý':'' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end gap-4">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'inventory-manager' }"
                variant="outline-secondary"
              >Huỷ</b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="isUpdate?updateInventory():createInventory()"
              >{{isUpdate?"Cập nhật":"Tạo Kho"}}</b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import appUtils from "@/utils/appUtils";

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      form: {},
      workspaces: [],
      members: [],
      isLoading: false
    };
  },
  computed: {
    organizations() {
      const userLocal = JSON.parse(localStorage.getItem("user"));
      return (
        userLocal?.members
          ?.filter(itemFilter => [1, 2].includes(itemFilter?.role))
          ?.map(item => item?.org) || []
      );
    },
    isUpdate() {
      return !!this.$route.params.id;
    }
  },
  watch: {
    $route() {
      this.getInventoryDetail();
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getInventoryDetail();
    }
  },
  methods: {
    async handleSelectOrg(org_id, isGetMember = true) {
      this.form.manager_id = null;
      this.form.ws_id = null;
      const response = await this.$store.dispatch(
        "inventoryManager/getWs",
        org_id
      );
      this.workspaces = response?.data?.data || [];
      // if (!isGetMember) return;
      // await this.getOrgMembers("", org_id);
    },
    // async getOrgMembers(search, org_id) {
    //   const params = {
    //     orgId: org_id,
    //     page: 1,
    //     limit: 1000,
    //     keyword: search,
    //     status: 1
    //   };
    //   const response = await this.$store.dispatch(
    //     "inventoryManager/getOrgMembers",
    //     params
    //   );

    //   this.members = response?.data?.data || [];
    // },
    async getWsMembers(search, ws_id) {
      const params = {
        page: 1,
        limit: 1000,
        q: search
      };
      const response = await this.$store.dispatch(
        "inventoryManager/getWsMembers",
        { params, id: ws_id }
      );
      this.members =
        response?.data?.data?.data?.map(item => ({
          ...item,
          user_id: item?.doctor?.user_id,
          name: item?.doctor?.name || item?.doctor?.user?.email
        })) || [];
    },
    handleDebounce: appUtils.debounce(function(search) {
      this.getMembers(search, this.form.org_id, this.form.ws_id);
    }, 1000),
    async getMembers(search, org_id, ws_id) {
      if (ws_id) {
        await this.getWsMembers(search, ws_id);
      } else {
        // await this.getOrgMembers(search, org_id);
      }
    },
    async createInventory() {
      this.$refs.CreateInventory.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true;

            const params = {
              ...this.form,
              ws_id: this.form.ws_id || 0
            };

            await this.$store.dispatch(
              "inventoryManager/createInventory",
              params
            );
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tạo Kho thành công",
                icon: "CheckCircleIcon",
                variant: "success"
              }
            });
            this.$router.replace({ name: "inventory-manager" });
          } catch (error) {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Có lỗi xảy ra khi tạo Kho",
                icon: "AlertTriangleIcon",
                variant: "danger"
              }
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    async getInventoryDetail() {
      try {
        const response = await this.$store.dispatch(
          "inventoryManager/getInventoryDetail",
          this.$route.params.id
        );
        if (response.data?.ws_id && response?.data?.org_id) {
          await this.handleSelectOrg(response?.data?.org_id, false);
          await this.getMembers(
            "",
            response?.data?.org_id,
            response?.data?.ws_id
          );
          this.form = {
            name: response.data?.name,
            description: response.data?.description,
            location: response.data?.location,
            org_id: response.data?.org_id,
            ws_id: response.data?.ws_id,
            manager_id: response.data?.manager_id
          };
        } else {
          await this.handleSelectOrg(response?.data?.org_id, false);
          await this.getMembers(
            "",
            response?.data?.org_id,
            response?.data?.ws_id
          );
          this.form = {
            name: response.data?.name,
            description: response.data?.description,
            location: response.data?.location,
            org_id: response.data?.org_id,
            ws_id: response.data?.ws_id || null,
            manager_id: response.data?.manager_id
          };
        }
      } catch (error) {
        console.log(error);

        const code = error.response?.data?.code;
        if (code === 500001) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Bạn không có quyền vào Kho này",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateInventory() {
      this.$refs.CreateInventory.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true;

            const params = {
              ...this.form,
              ws_id: this.form.ws_id || 0
            };

            await this.$store.dispatch("inventoryManager/updateInventory", {
              id: this.$route.params.id,
              params
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Cập nhật Kho thành công",
                icon: "CheckCircleIcon",
                variant: "success"
              }
            });
            this.$router.replace({ name: "inventory-manager" });
          } catch (error) {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Có lỗi xảy ra khi Cập nhật Kho",
                icon: "AlertTriangleIcon",
                variant: "danger"
              }
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}
</style>
