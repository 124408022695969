var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-card',{staticClass:"product-add-wrapper"},[_c('validation-observer',{ref:"CreateInventory"},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Tên Kho","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Tên Kho "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Tên Kho không được để trống':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Mô tả"}},[_c('validation-provider',{attrs:{"name":"Mô tả"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Địa chỉ"}},[_c('validation-provider',{attrs:{"name":"Địa chỉ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Tổ chức","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Tổ Chức "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"name","clearable":false,"options":_vm.organizations,"reduce":function (option){ return option.id; }},on:{"input":_vm.handleSelectOrg},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.form.org_id),callback:function ($$v) {_vm.$set(_vm.form, "org_id", $$v)},expression:"form.org_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Vui lòng chọn Tổ chức':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Khoa/Phòng Ban","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Khoa/Phòng Ban "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"name","reduce":function (option){ return option.id; },"options":_vm.workspaces},on:{"input":function (){
                  _vm.form.manager_id=null
                  _vm.getMembers('',_vm.form.org_id,_vm.form.ws_id)}},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.form.ws_id),callback:function ($$v) {_vm.$set(_vm.form, "ws_id", $$v)},expression:"form.ws_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Vui lòng chọn Khoa/Phòng Ban':''))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Quản lý","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('span',{staticClass:"d-block",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" Quản lý "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"name","filterable":false,"reduce":function (option){ return option.user_id; },"options":_vm.members},on:{"search":_vm.handleDebounce},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.form.manager_id),callback:function ($$v) {_vm.$set(_vm.form, "manager_id", $$v)},expression:"form.manager_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]?'Vui lòng chọn Người Quản Lý':''))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end gap-4",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'inventory-manager' },"variant":"outline-secondary"}},[_vm._v("Huỷ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){_vm.isUpdate?_vm.updateInventory():_vm.createInventory()}}},[_vm._v(_vm._s(_vm.isUpdate?"Cập nhật":"Tạo Kho"))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }